<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">
 <h4 class="faq">Frequently Asked Questions(FAQ)</h4>
<hr>
<div id="accordion">
  <div class="card" v-for="(faq, index) in faqs" :key="faq.id">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button 
          class="btn btn-link "  
          data-toggle="collapse" 
          :data-target="'#collapse'+index" 
          aria-expanded="true" 
          aria-controls="collapseOne" style="color:#000">
         <h5> {{faq.title}}</h5>
        </button>
      </h5>
    </div>

    <div :id="'collapse'+index" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
    <p> {{faq.description}}</p>
      </div>
    </div>
  </div>

</div>

</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'


export default {
  name: 'Home',
  components: {
    Header,
   Footer
  },
  data() {
    return {
      faqs:[]
    }
  },
  methods: {
    faq(){
      baseUrl.get('/api/getAllFAQs')
      .then((res)=>{
        this.faqs = res.data
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  },
  created() {
    this.faq()
  },
}
</script>
<style scoped>
.faqInfo{
margin-top: 200px;
}
#accordion{
padding: 50px;
}
.faq{
padding-left: 70px;
}
h4{
font-size: 24px;
}
</style>